import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import VideoComp from "../Components/VideoComp";
import { authInstance } from "../Axios";
import axios from "axios";

type Props = {};

const Videos = (props: Props) => {
  const [postData, setPostData] = useState([]);
  const [postCount, setPostCount] = useState(0);
  const [allComunity, setallComunity] = useState([]);
  const userId = localStorage.getItem("userId");

  const getData = async (page = 1) => {
    console.log("getallComunity12")
    let res = await authInstance.get(`getPlays?page=${page}&limit=10&userId=${userId}`);
    setPostData(res.data);
  };

  const getCountData = async (page = 1) => {
    try {
      console.log("getallComunity23")
      const res = await authInstance.get(`getPlaysCount?page=${page}&limit=10&userId=${userId}`);
      setPostCount(res.data.totalPostsCount);
    } catch (error) {
      console.error("Error fetching data count:", error);
    }
  };

  const getallComunity = async () => {
    try {
      console.log("getallComunity called");
      const userString = localStorage.getItem("user");
  
      if (!userString) {
        console.error("No user found in localStorage");
        return;
      }
  
      const currentUser = JSON.parse(userString);
      const currentUserEmail = currentUser.email;
  
      if (!currentUserEmail) {
        console.error("No email found for current user");
        return;
      }
  
      const response = await axios.get("https://schooltalkzdatabase.azurewebsites.net/api/v1/getallComunity", {
        headers: {
          "Content-Type": "application/json",
        },
        maxBodyLength: Infinity,
      });
  
      if (!response.data || !response.data.data) {
        console.error("Unexpected API response structure", response);
        return;
      }
  
      const data = response.data.data;
      console.log("API Response Data:", data);
  
      // Ensure that the creator and email properties exist before logging
      if (data.length > 0 && data[0].creator) {
        console.log("Current User Email:", currentUser.email, "First Community Creator Email:", data[0].creator.email);
      }
  
      //@ts-ignore
      const matchingComunity = data.filter((item) => item.creator && item.creator.email === currentUserEmail)
        //@ts-ignore
      .map((item) => ({
          id: item.id,
          description: item.description,
        }));
  
      console.log("Matching Community Data:", matchingComunity);
  
      setallComunity(matchingComunity);
    } catch (error) {
      console.error("Error in getallComunity:", error);
    }
  };
  

  useEffect(() => {
    getData();
    getallComunity();
    getCountData();
    
  }, []);


  return (
    <div className="bg-gradient-to-b from-[#0A1122] to-[#03194D] h-screen flex">
      <section className="w-[20%] ">
        <Sidebar />
      </section>
      <section className=" flex justify-center items-center bg-[#F2F2FF] w-[80%] h-[98%] mt-[1%] rounded-t-2xl ">
        <VideoComp
          posts={postData}
          getData={getData}
          //@ts-ignore
          totalPostsCount={postCount}
          //@ts-ignore
          getCountData={getCountData}
          //@ts-ignore
          allComunity = {allComunity}
          //@ts-ignore
          getallComunity = {getallComunity}
        />
      </section>
    </div>
  );
};

export default Videos;
