import React, { useState } from "react";
import logo from "../Assets/School Talkz Logo-02 2.png";
import dashboardIcon from "../Assets/dashboard-square-02.png";
import uploadIcon from "../Assets/upload-03.png";
import analyticIcon from "../Assets/analytics-01.png";
import walletIcon from "../Assets/wallet-02.png";
import logoutIcon from "../Assets/logout.png"
import LogoutButton from "./LogOut";

type Props = {};

const Sidebar = (props: Props) => {
  const [isSelected, setIsSelected] = useState(1);
  const handleLogout = () => {
    // Clear cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });

    // Redirect to index page
    window.location.href = '/'; // or any other route you want to redirect to
  };
  return (
    <div className=" w-full h-screen bg-transparent flex flex-col gap-4 pl-5 pt-5 ">
      <div className=" flex justify-center mb-4">
        <img src={logo} alt="Schooltalkz Logo" />
      </div>
      <div className="flex flex-col justify-center h-20  text-gray-500">
        <button
          onClick={() => {
            console.log("clicked");
            setIsSelected(0);
          }}
          className={` w-[100%] flex items-center gap-4 pl-4 hover:text-white hover:font-semibold hover:bg-[#061A6F] p-3 rounded-tl-lg rounded-bl-lg  `}
          style={{
            background: isSelected === 0 ? "#061A6F" : "transparent",
            color: isSelected === 0 ? "white" : "",
            fontWeight: isSelected === 0 ? "bold" : "",
          }}
        >
          <img src={dashboardIcon} alt="Dashboard Icon" />
          <p>Dashboard</p>
        </button>
      </div>

      <div className="flex flex-col justify-center h-20  text-gray-500">
        <button
          onClick={() => {
            console.log("clicked");
            setIsSelected(1);
          }}
          style={{
            background: isSelected === 1 ? "#061A6F" : "transparent",
            color: isSelected === 1 ? "white" : "",
            fontWeight: isSelected === 1 ? "bold" : "",
          }}
          className=" w-[100%] flex items-center gap-4 pl-4 hover:text-white hover:font-semibold hover:bg-[#061A6F] p-3 rounded-tl-lg rounded-bl-lg "
        >
          <img src={uploadIcon} alt="Upload Icon" />
          <p>Uploads</p>
        </button>
      </div>
      <div className="flex flex-col justify-center h-20  text-gray-500">
        <button
          onClick={() => {
            console.log("clicked");
            setIsSelected(2);
          }}
          style={{
            background: isSelected === 2 ? "#061A6F" : "transparent",
            color: isSelected === 2 ? "white" : "",
            fontWeight: isSelected === 2 ? "bold" : "",
          }}
          className=" w-[100%] flex items-center gap-4 pl-4 hover:text-white hover:font-semibold hover:bg-[#061A6F] p-3 rounded-tl-lg rounded-bl-lg "
        >
          <img src={analyticIcon} alt="Analytics Icon" />
          <p>Analytics</p>
        </button>
      </div>
      <div className="flex flex-col justify-center h-20  text-gray-500">
        <button
          onClick={() => {
            console.log("clicked");
            setIsSelected(3);
          }}
          style={{
            background: isSelected === 3 ? "#061A6F" : "transparent",
            color: isSelected === 3 ? "white" : "",
            fontWeight: isSelected === 3 ? "bold" : "",
          }}
          className=" w-[100%] flex items-center gap-4 pl-4 hover:text-white hover:font-semibold hover:bg-[#061A6F] p-3 rounded-tl-lg rounded-bl-lg "
        >
          <img src={walletIcon} alt="Earning Icon" />
          <p>Earning</p>
        </button>
      </div>
      <div className="flex flex-col justify-center h-20  text-gray-500">
        <button
          onClick={() => {
            console.log("clicked");
            setIsSelected(4);
             handleLogout()
          }}
          style={{
            background: isSelected === 4 ? "#061A6F" : "transparent",
            color: isSelected === 4 ? "white" : "",
            fontWeight: isSelected === 4 ? "bold" : "",
          }}
          className=" w-[100%] flex items-center gap-4 pl-4 hover:text-white hover:font-semibold hover:bg-[#061A6F] p-3 rounded-tl-lg rounded-bl-lg "
        >
          <img src={logoutIcon} style={{
            height:"2rem",
            width:"2rem"
          }} alt="Earning Icon" />
          <p>Logout</p>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
