import React, { useEffect, useState } from "react";
import cameraIcon from "../Assets/video-02.png";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin5Line } from "react-icons/ri";
import PostComposer from "./PostComposer";
import SingleReel from "./SingleReel";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  posts: any;
  totalPostsCount: any;
  allComunity:any;
  getData: (page: number) => void;
  getCountData: (page: number) => void;
  getallComunity:() => void;
};

const VideoComp = ({ posts, getData, totalPostsCount, getCountData, allComunity, getallComunity }: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [createPostOpened, setCreatePostOpened] = useState(false);
  const [showReel, setShowReel] = useState(false);
  const [selectedElement, setSelectedElement] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [elementToDelete, setElementToDelete] = useState<any>(null);
  //const [allComunity, setallComunity] = useState<any>(null);

  useEffect(() => {
    getData(currentPage);
    getCountData(currentPage);
    getallComunity();
  }, [currentPage]);

  const handleCreateContentClick = () => {
    setShowPopup(true);
  };

  const handleBackgroundClick = (event: any) => {
    if (event.target === event.currentTarget) {
      setShowPopup(false);
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleDeleteClick = (el: any) => {
    setElementToDelete(el);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const res = await axios.put(`https://schooltalkzdatabase.azurewebsites.net/api/v1/plays/${elementToDelete.id}`);

      if (res.status === 200) {
        setShowDeleteModal(false);
        toast.success('Play deleted successfully!', {
          onClose: () => {
            setElementToDelete(null);
          }
        });
      } else {
        toast.error(`Facing issue currently while deleting video!`);
      }

      getData(currentPage);
      getCountData(currentPage);
    } catch (error) {
      console.error("Error deleting item:", error);
      setShowDeleteModal(false);
      setElementToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setElementToDelete(null);
  };

  //@ts-ignore
  // const formatDateTimeIST = (dateString) => {
  //   const date = new Date(dateString);

  //   const utcOffset = date.getTimezoneOffset();
  //   const istOffset = 330;
  //   const istTime = new Date(date.getTime() + (istOffset + utcOffset) * 60000);

  //   const day = String(istTime.getDate()).padStart(2, '0');
  //   const month = String(istTime.getMonth() + 1).padStart(2, '0');
  //   const year = istTime.getFullYear();

  //   let hours = istTime.getHours();
  //   const minutes = String(istTime.getMinutes()).padStart(2, '0');
  //   const ampm = hours >= 12 ? 'PM' : 'AM';
  //   hours = hours % 12;
  //   hours = hours ? hours : 12;
  //   const strHours = String(hours).padStart(2, '0');

  //   return `${day}-${month}-${year} ${strHours}:${minutes} ${ampm}`;
  // };

  const formatDateTimeIST = (dateString) => {
    const date = new Date(dateString);

    const utcOffset = date.getTimezoneOffset();
    const istOffset = 330;
    const istTime = new Date(date.getTime() + (istOffset + utcOffset) * 60000);

    const day = String(istTime.getDate()).padStart(2, '0');
    const month = String(istTime.getMonth() + 1).padStart(2, '0');
    const year = istTime.getFullYear();
    const hours = String(istTime.getHours()).padStart(2, '0');
    const minutes = String(istTime.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  return (
    <div className="flex flex-col gap-3 h-[90%] bg-white w-[95%] rounded-2xl ">
      <div className="flex justify-between w-full h-[10%] items-center p-4">
        <div className="font-bold ml-4 text-xl">Videos ({totalPostsCount})</div>

        <div className="flex  w-[20%] relative ">
          <button
            className="flex gap-4  justify-center rounded-xl border-2 border-[#061A6F] p-2 text-[#061A6F] font-semibold"
            onClick={handleCreateContentClick}
          >
            <img src={cameraIcon} alt="Camera Icon" />
            <p>Create Content</p>
          </button>
          {showPopup && (
            <div className="">
              <div
                className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 z-40"
                onClick={handleBackgroundClick}
              ></div>
              <div className="createContent flex flex-col gap-3 w-60 text-center absolute top-12 right-12 bg-white border border-[#061A6F] rounded-lg shadow-lg z-50    ">
                <div className="p-2 border-b border-[#DFDFDF] cursor-pointer ">
                  Create Post
                </div>
                <div className="p-2 border-b border-[#DFDFDF] cursor-pointer ">
                  Create Reel
                </div>
                <div
                  className="p-2 cursor-pointer "
                  onClick={() => {
                    setCreatePostOpened(true);
                    setShowPopup(false);
                  }}
                >
                  Create Play
                </div>
              </div>
            </div>
          )}
          {createPostOpened && (
            <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-50">
              <div className="fixed inset-0 bg-black bg-opacity-80  blur-lg"></div>
              <div className="relative z-10">
                <PostComposer
                  //@ts-ignore
                  getData={getData}
                  //@ts-ignore
                  getCountData={getCountData}
                  functionClose={() => {
                    setCreatePostOpened(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="px-6">
        <p className="font-bold">My Channels</p>
        {
          allComunity.map((el: any) => (
            <span key={el.id} className="mr-3">{el.description} </span>
          ))
        }
      </div>
      <div className="w-full h-fit max-h-[80%] overflow-y-auto">
        <table className="w-full">
          <thead className="border-b border-[#DFDFDF] sticky top-0 bg-white">
            <tr className="h-14">
              <th className="w-[37%] text-left pl-4">Content</th>
              <th className="w-[13.5%]">Updated At</th>
              <th className="w-[13%]">Category</th>
              <th className="w-[13%]">Likes</th>
              <th className="w-[13%]">Comments</th>
              <th className="w-[12%]">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {posts && posts.length > 0 ? (
              posts.map((el: any) => (
                <tr key={el.id} className="h-20">
                  <td
                    className="flex gap-6 p-2 align-middle  cursor-pointer"
                    onClick={() => {
                      setSelectedElement(el);
                      setShowReel(true);
                    }}
                  >
                    {el.assets && el.assets.length > 0 && el.assets[0] && (
                      <>
                      
                        {
                        el.thumbnailsArr && el.thumbnailsArr.length > 0 ? (
                          <img
                            className="h-20 w-[40%] rounded-lg"
                            src={el.thumbnailsArr[0]}
                            alt={el.description + " thumbnail"}
                          />
                        ) : el.thumbnail ? (
                          <img
                            className="h-20 w-[40%] rounded-lg"
                            src={el.thumbnail}
                            alt={el.description + " thumbnail"}
                          />
                        ) : (
                          el.assets[0].includes(".mp4") ? (
                            <video
                              className="h-20 w-[40%] rounded-lg"
                              src={el.assets[0]}
                            // controls
                            />
                          ) : (
                            <img
                              className="h-20 w-[40%] rounded-lg"
                              src={el.assets[0]}
                              alt={el.description + " image"}
                            />
                          )
                        )}
                      </>
                    )}

                    <div className="flex flex-col justify-center w-[30%]">
                      <p className="overflow-hidden line-clamp-2">
                        {el.description}
                      </p>
                    </div>
                  </td>
                  <td className="text-center align-middle">
                    {formatDateTimeIST(el.updated_at)}
                  </td>
                  <td className="text-center align-middle">
                    {el.category}
                  </td>
                  <td className="text-center align-middle">
                    {el._count && el._count.liked_by}
                  </td>
                  <td className="text-center align-middle">
                    {el._count && el._count.children}
                  </td>
                  <td className="h-full align-middle">
                    <div className="flex justify-center gap-5">
                      <p
                        onClick={() => console.log("Edit Clicked")}
                        className="text-xl cursor-pointer"
                      >
                        <TbEdit />
                      </p>
                      <p
                        //onClick={() => console.log("Delete Clicked")}
                        onClick={() => handleDeleteClick(el)}
                        className="text-xl cursor-pointer text-red-500"
                      >
                        <RiDeleteBin5Line />
                      </p>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center py-4 text-red-500">
                  No posts to show on this page.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <hr className="text-[#DFDFDF]" />
      <div className="flex justify-between p-3">
        <div>
          <span
            className={`p-2 bg-[#9C9C9C] text-[#000000] rounded-lg cursor-pointer ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePrevPage}
          >
            Prev
          </span>
          {currentPage}
          <span
            className={`p-2 bg-[#9C9C9C] text-[#000000] rounded-lg cursor-pointer`}
            onClick={handleNextPage}
          >
            Next
          </span>
        </div>
      </div>
      {showReel && selectedElement ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 m-auto flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black bg-opacity-90  blur-lg"></div>
          <div className="relative z-10  h-screen w-screen">
            <SingleReel el={selectedElement} setShowReel={setShowReel} />
          </div>
        </div>
      ) : (
        <>{console.log("no")}</>
      )}
      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black bg-opacity-50"></div>
          <div className="bg-white p-6 rounded-lg shadow-lg z-10">
            <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this item?</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancelDelete}
                className="px-4 py-2 bg-gray-200 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default VideoComp;
