import React from "react";
import { Route, Routes } from "react-router-dom";
import Videos from "../Pages/Videos";
import Login from "../Pages/Login";

type Props = {};

const AllRoutes = (props: Props) => {
  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/createPost" element={<Videos />}></Route>
    </Routes>
  );
};

export default AllRoutes;
