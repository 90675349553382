import React, { useState } from "react";
import SchoolTalkzLogo from "../Assets/School Talkz Logo-02 2.png";
import { authInstance } from "../Axios";
import { authInstance2 } from "../Axios";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

type Props = {};

const Login = (props: Props) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loginButtonText, setLoginButtonText] = useState("Send OTP");
  const [otpSentStatus, setOtpSentStatus] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();

  const LoginUserhandler = async (e: any) => {
    e.preventDefault();
    setLoginButtonText("Sending OTP");
    const passwordBlock = document.getElementById("Password");
    try {
      if (email) {
        let data = {
          email: email,
        };
        let res = await authInstance2.put(`sendOTP`, data);
        console.log(res);
        if (res.data.message == "No user with this credentials") {
          setLoginButtonText("Send OTP");
        } else if (res.data.message == "OTP sent") {
          if (passwordBlock) {
            passwordBlock.style.display = "block";
            setLoginButtonText("Verify OTP");
            setOtpSentStatus(true);
          }
        } else {
          setLoginButtonText("Send OTP");
        }
      } else {
        console.log("Error here");
        return;
      }
    } catch (error) {
      console.log("Internal Server Error");
    }
  };

  const verifyOtphandler = async (e: any) => {
    e.preventDefault();
    try {
      let data = {
        email: email,
        otp: password,
      };
      if (data.email && data.otp) {
        let res = await authInstance2.put(`/verifyOTP`, data);
        console.log(res);
        if (res.data.success === true) {
          const token = res.data.user.device_token;
          setCookie("token", token, { path: "/" });
          localStorage.setItem("userId",res.data.user.id);
          localStorage.setItem("communityId",JSON.stringify(res.data.user.community[0].id));
          console.log("res.data.user.community[0].id",res.data.user.community[0].id)
          localStorage.setItem("user", JSON.stringify(res.data.user));
          navigate("/createPost");
        }
      }
    } catch (error) {}
  };
  return (
    <div className="block bg-light-bg-blue xl:grid grid-cols-2 gap-4">
      <div className="hidden xl:flex flex-col justify-center items-center  min-h-screen loginbtn rounded-tr-[155px] rounded-br-[155px]">
        <div className="scale-[4]">
          <img className="h-12 w-auto " src={SchoolTalkzLogo} alt="" />
        </div>
      </div>
      <div className="h-screen xl:h-auto flex py-5 justify-center items-center">
        <div className="bg-white dark:bg-dark-1 xl:bg-transparent py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-[60%] flex justify-center items-center">
          <div className="tab-content mt-10  w-[80%] xl:w-full">
            <div>
              <div className="intro-x mt-2 text-gray-500 xl:hidden text-center">
                Get more things done with Edfish platform. The Complete
                Ecosystem of Education
              </div>
              <div className="intro-x w-full mt-8">
                <h3 className="font-bold text-xl p-4 pl-0">
                  {/* {selectSchoolData.name} */}
                </h3>
                <form
                  onSubmit={
                    !otpSentStatus ? LoginUserhandler : verifyOtphandler
                  }
                >
                  <input
                    id="Eamil"
                    type="text"
                    className="intro-x border-2 border-gray-300 form-control w-full rounded-md py-3 px-4 outline-none block"
                    placeholder="Eamil"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <br />
                  <input
                    id="Password"
                    type="Password"
                    className="intro-x border-2 border-gray-300 form-control w-full rounded-md py-3 px-4 outline-none  hidden"
                    placeholder="OTP"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="intro-x mt-5 xl:mt-8 text-center xl:text-center flex gap-4 w-full">
                    <button
                      type="reset"
                      className="rounded-[20px] px-4 w-full loginbtn text-white"
                    >
                      Back
                    </button>
                    <button
                      id="loginButton"
                      type="submit"
                      data-usertype="admin"
                      //   disabled={email == "" ? true : false}
                      className="rounded-[20px] loginbtn text-white py-3 px-4 w-full btn-lg p-3 align-top text-center"
                    >
                      {loginButtonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
