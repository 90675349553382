import React from "react";
import "./App.css";
import PostComposer from "./Components/PostComposer";
import Videos from "./Pages/Videos";
import Login from "./Pages/Login";
import AllRoutes from "./Routers/AllRoutes";

function App() {
  return (
    <div className="">
      <AllRoutes />
    </div>
  );
}

export default App;
