import React, { Dispatch, SetStateAction } from "react";
import { FaRegComment, FaRegHeart } from "react-icons/fa";
import { LiaEyeSolid } from "react-icons/lia";
import logoImg from "../Assets/School Talkz Logo-02 2.png";
type Props = {
  el: any;
  setShowReel: Dispatch<SetStateAction<any>>;
};

const SingleReel = ({ el, setShowReel }: Props) => {
  console.log(el);
  return (
    <div className="relative  justify-center flex flex-col  h-screen w-[100%]  ">
      <div className="flex  h-[98%] w-[35%] absolute top-0 left-0 right-0 m-auto ">
        <div className="h-[100%] w-[88%] p-1 ">
          <video
            className="h-[100%] rounded-2xl "
            src={el.assets[0]}
            controls
          />
        </div>
        <div className=" p-1 w-[12%] flex flex-col gap-1 justify-between ">
          <div className=" flex self-center mt-2  ">
            <div
              onClick={() => {
                setShowReel(false);
              }}
              className="relative top-0 bottom-0 left-0 right-0 m-auto  h-5 w-5 cursor-pointer "
            >
              <div className="w-5 h-0 absolute top-0 bottom-0 m-auto border border-white rotate-45"></div>
              <div className="w-5 h-0 absolute top-0 bottom-0 m-auto border border-white rotate-[-45deg]"></div>
            </div>
          </div>
          <div className=" flex flex-col gap-2  mb-4">
            <div className=" w-full ">
              <div className="flex flex-col gap-1 text-center  text-white ">
                <FaRegHeart className="text-2xl self-center  " />
                <p>{el._count && el._count.liked_by}</p>
              </div>
            </div>
            <div className=" w-full ">
              <div className="flex flex-col gap-1 text-center  text-white ">
                <FaRegComment className="text-2xl self-center  " />
                <p className=" w-full line-clamp-1 ">
                  {el._count && el._count.children}
                </p>
              </div>
            </div>
            <div className=" w-full  mb-3">
              <div className="flex flex-col gap-1 text-center  text-white ">
                <LiaEyeSolid className="text-2xl self-center  " />
                <p>{(el._count && el._count.views) || 0}</p>
              </div>
            </div>
            <div className=" w-full ">
              <div className="flex flex-col gap-1 text-center  text-white ">
                <img
                  className="h-10 w-10 rounded-md"
                  src={el.posted_by.user.profile_pic || logoImg}
                  alt="user profile image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleReel;
