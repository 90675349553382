import axios, { AxiosInstance } from "axios";

export const baseURL = "https://schooltalkzdatabase.azurewebsites.net/api/v1";
export const baseURL2 = `http://20.197.19.129:1112/`;
export const authInstance: AxiosInstance = axios.create({
  baseURL: baseURL,
});
export const authInstance2: AxiosInstance = axios.create({
  baseURL: baseURL2,
  headers: { "Content-Type": "application/json" },
  maxBodyLength: Infinity,
  withCredentials: true,
});
