import React, { useState, useRef } from 'react';
import axios from 'axios';
import { IoVideocamOutline, IoCloseOutline } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  functionClose: () => void;
  getData: () => void;
  getCountData: () => void;
  getallComunity:() => void;
}

const PostComposer = ({ functionClose, getData, getCountData, getallComunity  }: Props) => {
  const [description, setDescription] = useState("");
  const userId = localStorage.getItem("userId");
  const communityId = localStorage.getItem("communityId");
  const [category, setCategory] = useState("Default");
  const [targetaudience, setTargetAudience] = useState("");
  const [labels, setLabels] = useState("");

  const videoInputRef = useRef<HTMLInputElement>(null);
  const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
  const [selectedVideoString, setSelectedVideoString] = useState<string>("");
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const userString = localStorage.getItem("user") || '{}';
  const user = JSON.parse(userString);
  const [loading, setLoading] = useState(false);

  const handleVideoClick = () => {
    videoInputRef.current?.click();
  };

  const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedVideo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedVideoString(reader.result as string);
        extractThumbnail(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const extractThumbnail = async (videoFile: File) => {
    const video = document.createElement('video');
    const url = URL.createObjectURL(videoFile);
    video.src = url;

    await new Promise((resolve) => {
      video.onloadedmetadata = () => resolve(null);
    });

    video.currentTime = 3;

    await new Promise((resolve) => {
      video.onseeked = () => resolve(null);
    });

    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext('2d');
    ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      if (blob) {
        const thumbnail = new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' });
        setThumbnailFile(thumbnail);
      }
    }, 'image/jpeg');

    URL.revokeObjectURL(url);
  };

  const handleDeleteVideo = () => {
    setSelectedVideo(null);
    setSelectedVideoString("");
    setThumbnailFile(null);
  };

  const handlePostNow = async () => {
    setLoading(true);
    console.log("Post Now clicked!");

    const formData = new FormData();
    formData.append("description", description);
    //@ts-ignore
    formData.append("userId", userId);
    //@ts-ignore
    formData.append("communityId", communityId);
    formData.append("category", category);
    // formData.append("targetaudience", targetaudience);
    
    formData.append("isPlays", "true");
    if (selectedVideo) {
      formData.append("postImages", selectedVideo);
    }
    if (thumbnailFile) {
      formData.append("thumbnails", thumbnailFile);
    }

    try {
      const res = await axios.post(
        'https://schooltalkzdatabase.azurewebsites.net/api/v1/createPostByCreator',
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 600000,
        }
      );
      console.log("Play created successfully:", res.data);
      if (res.status === 201) {
        toast.success('Play created successfully!', {
          onClose: () => {
            setIsPreviewOpen(false);
            functionClose();
          }
        });
      } else {
        toast.error(`Facing issue currently while uploading video!`);
      }

      getData();
      getCountData();
      getallComunity();

    } catch (error) {
      console.error("Internal Server Error", error);
      toast.error(`Facing issue currently while uploading video!`);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const handleNextStep = () => {
    if (!selectedVideo) {
      toast.error('Upload a video to proceed!');
      return;
    }
    setIsPreviewOpen(true)

  }

  return (
    <div className="relative h-screen w-screen overflow-hidden">
      <div className="absolute inset-0 w-full h-full object-cover blur-md"></div>
      <div className="absolute inset-0 flex items-center justify-center ">
        <div className="h-[80%] w-[60%] rounded-2xl bg-slate-200 overflow-y-auto">
          <div className="relative w-full h-[10%] rounded-t-2xl bg-white">
            <p className="p-5 text-xl font-bold text-center">Create Play</p>
            <div
              onClick={functionClose}
              className="absolute top-0 bottom-0 m-auto right-5 h-5 w-5 cursor-pointer"
            >
              <div className="w-5 h-0 absolute top-0 bottom-0 m-auto border border-black rotate-45"></div>
              <div className="w-5 h-0 absolute top-0 bottom-0 m-auto border border-black rotate-[-45deg]"></div>
            </div>
          </div>
          <div className="h-[80%] p-7 pl-9 pr-9 flex flex-col justify-between w-full">
            <div className="flex w-full h-[100px]">
              <div className="flex flex-col justify-center ml-5 h-full">
                <img
                  className="h-[70px] w-[70px] rounded-full"
                  src={user.profile_pic || "https://media.istockphoto.com/id/1186723101/photo/digital-3d-illustration-of-a-toon-girl.jpg?s=1024x1024&w=is&k=20&c=Du31UuZDt5BJuYcFLZP9P-sS47ik-BDEl2SUxNf1h58="}
                  alt="Avatar"
                />
              </div>
              <div className="flex flex-col justify-center ml-3 h-full">
                <div>
                  <p className="font-semibold text-[16px]">
                    {user.first_name} {user.last_name} <span>(Creator)</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="border-2 border-gray-400 shadow-md rounded-lg w-full h-[50%]">
              <textarea
                onChange={(e) => setDescription(e.target.value)}
                className="h-full w-full bg-transparent p-3"
                placeholder="What's on your mind? Share it with your peers."
              ></textarea>
            </div>
            <div className="border-2 border-gray-400 shadow-md rounded-lg w-full h-[10%]">
              <input
                type="text"
                className="h-full w-full bg-transparent p-2"
                placeholder="Create or search for labels"
                onChange={(e) => setLabels(e.target.value)}
              />
            </div>
          </div>
          <div className="h-[10%] pl-9 pr-9 flex justify-between w-full ">
            <div className="w-[10%] flex gap-5">
              <button
                className="content-center h-fit self-center p-2 text-[24px] bg-gray-300 rounded-xl hover:bg-gray-400"
                onClick={handleVideoClick}
              >
                <IoVideocamOutline />
              </button>
            </div>
            <div className="w-[100%] flex justify-start p-3">
              {selectedVideoString && (
                <div className="flex justify-center w-full">
                  <div className="relative w-[50%]">
                    <video
                      controls
                      className="w-full h-[400px] object-cover rounded-md"
                    >
                      <source src={selectedVideoString} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <button
                      onClick={handleDeleteVideo}
                      className="absolute top-0 right-0 p-1 bg-red-500 rounded-full text-white"
                    >
                      <IoCloseOutline />
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="w-[25%] flex justify-end p-3">
              <button
                //disabled={!selectedVideo}
                className="content-center h-fit self-center p-2 text-[18px] bg-gray-300 rounded-xl hover:bg-gray-400"
                //onClick={() => setIsPreviewOpen(true)}
                onClick={handleNextStep}
              >
                Next Step
              </button>
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        accept="video/*"
        style={{ display: "none" }}
        ref={videoInputRef}
        onChange={handleVideoChange}
      />
      {isPreviewOpen && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-50">
          <div className="relative z-10 w-full">
            <div className="relative justify-center flex flex-col h-screen w-[100%]">
              <div className="flex flex-col h-[80%] w-[60%] rounded-lg bg-white absolute top-0 left-0 right-0 bottom-0 m-auto">
                <div className="relative w-full h-[10%] rounded-t-2xl bg-white border-b-2 border-gray-200 flex items-center">
                  <button onClick={() => setIsPreviewOpen(false)} className="p-3 text-left">
                    Go Back
                  </button>
                  <p className="p-3 text-xl font-bold text-center flex-grow">Preview Play</p>
                  <div
                    onClick={() => setIsPreviewOpen(false)}
                    className="absolute top-0 bottom-0 m-auto right-5 h-5 w-5 cursor-pointer"
                  >
                    <div className="w-5 h-0 absolute top-0 bottom-0 m-auto border border-black rotate-45"></div>
                    <div className="w-5 h-0 absolute top-0 bottom-0 m-auto border border-black rotate-[-45deg]"></div>
                  </div>
                </div>
                <div className="flex justify-between h-[90%] w-full p-1">
                  <div className="h-full w-[50%] text-red-500 text-2xl rounded-lg">
                    <video
                      className="h-full w-full rounded-2xl m-auto"
                      src={selectedVideoString}
                      controls
                    />
                  </div>
                  <div className="w-[50%] flex flex-col p-4 gap-8">
                    <div className="flex flex-col gap-2">
                      Description: {description}
                    </div>
                    <div className="flex flex-col gap-2">
                      Labels: {labels}
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="category">Category</label>
                      <select
                        name="category"
                        className="bg-gray-200 rounded-lg p-2"
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="Default">Default</option>
                        <option value="Tips and Tricks">Tips and Tricks</option>
                        <option value="DIY">DIY</option>
                        <option value="Science">Science</option>
                        <option value="Space">Space</option>
                        <option value="Nature">Nature</option>
                        <option value="Arts">Arts</option>
                        <option value="Talents">Talents</option>
                        <option value="Entertainments">Entertainments</option>
                        <option value="Sports">Sports</option>
                        <option value="Educational">Educational</option>
                      </select>
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="audience">Audience</label>
                      <select
                        name="audience"
                        className="bg-gray-200 rounded-lg p-2"
                        onChange={(e) => setTargetAudience(e.target.value)}
                      >
                        <option value="All">All</option>
                        <option value="KG">KG</option>
                        <option value="Class 1 to Class 3">Class 1 to Class 3</option>
                        <option value="Class 4 to Class 5">Class 4 to Class 5</option>
                        <option value="Class 6 to Class 8">Class 6 to Class 8</option>
                        <option value="Class 9 to Class 10">Class 9 to Class 10</option>
                        <option value="Class 11 to Class 12">Class 11 to Class 12</option>
                      </select>
                    </div>
                    <div className="flex justify-center">
                      <button
                        className="w-[70%] content-center h-fit self-center p-2 text-[18px] bg-gray-300 rounded-xl hover:bg-gray-400"
                        onClick={handlePostNow}
                      >
                        Post Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {loading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  <div className="flex items-center">
                    <div role="status">
                      <svg aria-hidden="true" className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default PostComposer;
